import logo from './logo.svg';
import './App.css';

function App() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Check if the user-agent contains "Android" or "iOS" to determine the platform
  if (/android/i.test(userAgent)) {
      // Redirect to the Google Play Store for Android
      window.location.href = 'https://play.google.com/store/apps/details?id=com.kuza.com&pli=1';
  } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      // Redirect to the Apple App Store for iOS
      window.location.href = 'https://apps.apple.com/tz/app/kuza-business/id1616310003';
  } else {
      // For other platforms or if user-agent is not recognized, you can provide a fallback URL
      window.location.href = 'https://linktr.ee/kuzaBusiness';
  }
  return (
    <div >
      
    </div>
  );
}

export default App;
